.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'proxima';
}
.loading {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.box {
    color: black;
    padding: 40px 20px 40px 20px;
    box-sizing: border-box;
    width: 40%;
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: white;
    opacity: 0.8;
}
.greenC {
    width: 2.5vw;
    margin-bottom: 10px;
}
.thankText {
    font-size: 1.3vw;
    line-height: 35px;
}
.signUpBtn{
    padding: 10px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid black;
    color: white;
    font-family: 'proxima-bold';
    font-size: 1.2vw;
    margin-top: 20px;
    width: 40%;
    background-color: black;
}
.overlay {
    object-fit: contain;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
    background-image: url('../../assets/images/coke_bg_darker.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}
.stat {
    margin-bottom: 10px; 
    font-weight: bold; 
    font-size: 2vw;
}
@media (max-width: 768px) {
    .container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'proxima';
    }
    .loading {
        width: 100%;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .box {
        color: black;
        padding: 40px 20px 40px 20px;
        box-sizing: border-box;
        width: 90%;
        display: flex;
        flex-direction: column;
        border-radius: 18px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: white;
        opacity: 0.8;
    }
    .greenC {
        width: 10vw;
        margin-bottom: 10px;
    }
    .thankText {
        font-size: 5vw;
        line-height: 35px;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid black;
        color: white;
        font-family: 'proxima-bold';
        font-size: 5vw;
        margin-top: 20px;
        width: 40%;
        background-color: black;
    }
    .overlay {
        object-fit: contain;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 
        background-image: url('../../assets/images/coke_bg_darker.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }
    .stat {
        margin-bottom: 10px; 
        font-weight: bold; 
        font-size: 6vw;
    }
}
@media screen and (min-width: 2000px) {
    .container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'proxima';
    }
    .loading {
        width: 100%;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .box {
        color: black;
        padding: 70px 25px 70px 25px;
        box-sizing: border-box;
        width: 35%;
        display: flex;
        flex-direction: column;
        border-radius: 18px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: white;
        opacity: 0.8;
    }
    .greenC {
        width: 2.5vw;
        margin-bottom: 10px;
    }
    .thankText {
        font-size: 1.3vw;
        line-height: 35px;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid black;
        color: white;
        font-family: 'proxima-bold';
        font-size: 1.2vw;
        margin-top: 20px;
        width: 40%;
        background-color: black;
    }
    .overlay {
        object-fit: contain;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 
        background-image: url('../../assets/images/coke_bg_darker.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }
}