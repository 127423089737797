.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}
.mid{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.im {
    width: 60%;
}
.signUpBtn{
    padding: 10px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid white;
    color: white;
    font-family: 'proxima-bold';
    font-size: 1.5vw;
    margin-top: 30px;
}
.priceText {
    color: black;
    font-family: 'proxima-bold';
    font-size: 2.4vw;
    margin-top: 20px;
    margin-bottom: 20px;
}
.loading {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardCont {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.inp {
    all: unset;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 8px;
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.443);
    margin-bottom: 5px;
}
.review {
    width: 100%;
    height: 80px;
    background: rgb(131,83,215);
    background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'proxima-semi-bold';
    font-size: 1.6vw;
}
.whiteCont {
    display: flex;
    padding: 80px;
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    height: 100%;
}
.rightDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
}
.userNameDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    font-family: 'proxima-bold';
    font-size: 1.5vw;
}
.instaText {
    margin-top: 15px;
    font-family: 'proxima-bold';
    font-size: 2.5vw;
    display: flex;
    align-items: center;
}
.tags {
    padding: 10px 20px 10px 20px;
    box-sizing: border-box;
    background-color: #bcbcbc;
    border-radius: 18px;
    font-size: 14px;
    font-family: 'proxima-semi-bold';
}
.tagDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.text {
    font-size: 1.6vw;
    line-height: 35px;
}
.terms {
    margin-top: 20px;
}
.pay{
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'proxima-semi-bold';
    background-color: black;
    color: white;
    font-size: 1.5vw;
    margin-top: 20px;
}
.payDisabled {
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'proxima-semi-bold';
    background-color: black;
    color: white;
    font-size: 1.5vw;
    margin-top: 20px;
    opacity: 0.4;
    pointer-events: none;
}
.instaLogo{
    width: 12vw;
    margin-top: 20px;
}
.spot {
    width: 3vw;
}
.hrt {
    width: 2vw;
}
.payCon {
    width: 50%;
}
@media only screen and (max-width: 767px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
    }
    .mid{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .im {
        width: 100%;
    }
    .spot {
        width: 10vw;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        font-family: 'proxima-bold';
        font-size: 5vw;
        margin-top: 30px;
        width: 100%;
    }
    .priceText {
        color: black;
        font-family: 'proxima-bold';
        font-size: 6vw;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .loading {
        width: 100%;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cardCont {
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .inp {
        all: unset;
        padding: 10px;
        box-sizing: border-box;
        margin-top: 10px;
        border-radius: 8px;
        border: 2px solid rgba(0, 0, 0, 0.443);
        width: 100%;
        margin-bottom: 5px;
    }
    .review {
        width: 100%;
        height: 70px;
        background: rgb(131,83,215);
        background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
        margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: 'proxima-semi-bold';
        font-size: 4vw;
    }
    .whiteCont {
        display: flex;
        flex-direction: column;
        padding: 25px;
        box-sizing: border-box;
        background-color: white;
        width: 100%;
        height: 100%;
    }
    .rightDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        box-sizing: border-box;
    }
    .userNameDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-family: 'proxima-bold';
        font-size: 5vw;
        margin-top: 15px;
    }
    .instaText {
        margin-top: 15px;
        font-family: 'proxima-bold';
        font-size: 5vw;
        display: flex;
        align-items: center;
    }
    .tags {
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
        background-color: #bcbcbc;
        border-radius: 18px;
        font-size: 14px;
        font-family: 'proxima-semi-bold';
    }
    .tagDiv {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .text {
        font-size: 4vw;
        line-height: 35px;
    }
    .terms {
        margin-top: 20px;
    }
    .pay{
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        font-size: 4vw;
        margin-top: 20px;
    }
    .payDisabled{
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        font-size: 4vw;
        margin-top: 20px;
        opacity: 0.4;
        pointer-events: none;
    }
    .instaLogo{
        width: 22vw;
        margin-top: 10px;
    }
    .hrt {
        width: 6vw;
    }
    .payCon {
        width: 90%;
    }
}