.modalCont {
    width: 60%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: rgb(142,217,231);
    background: linear-gradient(317deg, rgba(142,217,231,0.95) 0%, rgba(131,83,215,0.95) 100%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    align-items: center;
    font-family: 'proxima';
    overflow: hidden;
    z-index: 20;
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
}
.titles {
    font-size: 1.8vw;
    font-family: 'proxima-black';
    margin-bottom: 20px;
}
.signUpBtn{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'proxima-semi-bold';
    margin-top: 30px;
    background-color: black;
    color: white;
    padding: 10px 35px 10px 35px;
    border-radius: 35px;
    font-size: 1.5vw;
}
.pText {
    color: white; 
    font-size: 1.4vw;
}
.close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    cursor: pointer;
    z-index: 12;
}
.cl {
    margin-top: 10px;
    font-family: 'proxima';
    font-size: 1.2vw;
    cursor: pointer;
}
@media only screen and (max-width: 767px) {
    .modalCont {
        width: 100%;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background: rgb(142,217,231);
        background: linear-gradient(317deg, rgba(142,217,231,0.95) 0%, rgba(131,83,215,0.95) 100%);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 40px;
        align-items: center;
        font-family: 'proxima';
        overflow: hidden;
        
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: black;
        cursor: pointer;
    }
    .titles {
        font-size: 6vw;
        font-family: 'proxima-black';
        margin-bottom: 20px;
    }
    .signUpBtn{
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        margin-top: 30px;
        background-color: black;
        color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 35px;
        font-size: 5vw;
    }
    .pText {
        color: white; 
        font-size: 4vw;
    }
    .cl {
        margin-top: 10px;
        font-family: 'proxima';
        font-size: 4vw;
        cursor: pointer;
    }
}