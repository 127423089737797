.tooltipContainer {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1;
    opacity: 0.9;
    display: none;
  }
  
  .tooltipContainer:hover .tooltip {
    display: block;
  }
  