.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container{
    width: 100%;
    font-family: 'proxima';
    position: relative;
}
.midC{
    padding-right: 10vw;
    padding-left: 10vw;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    position: relative;
    font-family: 'proxima';
    margin-top: 25vh;
}
.signupCont {
    display: flex; 
    align-items: center;
}
.learnM {
    margin-left: 30px;
     color: white;
    font-size: 1.5vw;
}
.instaLogo{
    width: 10vw;
}
.mainText{
    font-size: 5vw;
    color: white;
    font-family: 'proxima-black';
    animation: slideIn 0.9s ease-in;
    box-sizing: border-box;
}
.mobMain {
    display: none;
}
.mobDesk {
    display: block;
}

.secText{
    font-size: 2.3vw;
    color: white;
    font-family: 'proxima-bold';
    display: flex;
}
.signUpBtn{
    padding: 10px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid white;
    color: white;
    width: 200px;
    font-family: 'proxima-bold';
    font-size: 1.5vw;
}
.stepText {
    font-size: 2vw;
    color: white;
    font-family: 'proxima-black';
}
.landPic {
    width: 23vw;
}
.numText {
    font-size: 2vw;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    height: 35px;
    border: 2px solid white;
    border-radius: 50%;
    margin-right: 10px;
    font-family: 'proxima-black';
}
.insta{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arr{
    width: 35vw; 
    transform: scaleX(-1);
    rotate: 10deg;

}
.abs{
    position: absolute;
    bottom: -25vw;
    right: 25vw;
    z-index: -10;
}
.arr2{
    width: 30vw; 
    /* transform: scaleX(-1);
    filter: 'FlipH'; */
    rotate: 20deg;
    transform: scaleY(-1);
}
.abs2{
    position: absolute;
    top: 50px;
    left: 27vw;
}
.thirdStepC{
    padding-right: 10vw;
    padding-left: 10vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: white;
    justify-content: space-between;
    margin-top: 20vh;

}
.numText3{
    font-size: 2vw;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    height: 35px;
    border: 2px solid white;
    border-radius: 50%;
    margin-right: 10px;
    font-family: 'proxima-black';
}
.stepText3 {
    font-size: 2vw;
    color: white;
    font-family: 'proxima-black';
}
.overlay {
    object-fit: contain;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
    background-image: url('../../assets/images/coke_bg_darker.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
.rightC {
    font-family: 'proxima';
    background-color: rgba(255, 255, 255, 0.075);
    padding: 30px;
    box-sizing: border-box;
    border-radius: 25px;

}
.step3Text {
    font-size: 1.6vw;
    color: white;
}
.purple {
    background-color: rgba(255, 255, 255, 0.075);
    padding: 30px;
    box-sizing: border-box;
    border-radius: 25px;
}
.purple2 {
    background-color: rgba(255, 255, 255, 0.075);
    padding: 30px;
    box-sizing: border-box;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    width: 32vw;
}
.abtSec {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}
.abtCont {  
    /* background-color: rgba(0, 0, 0, 0.8); */
    padding: 30px;
    box-sizing: border-box;
    border-radius: 25px;
    width: 50%;
    height: 70%;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    padding-bottom: 60px;
    padding-top: 50px;
    margin-top: 70px;
}
.lgo {
    height: 90px;
    margin-bottom: 30px;
}
.abtText {
    font-size: 1.7vw;
    color: white;
    font-family: 'proxima-bold';
}
.abtLgo {
    margin-top: 15px;
    height: 10vw;
    margin-bottom: 35px;
}
.abtLgo2 {
    margin-top: 15px;
    width: 25vw;
    margin-bottom: 35px;
}
.abtPara {
    font-size: 2.2vw;
    color: white;
    font-family: 'proxima-semi-bold';
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;

}
.abtPara2 {
    font-size: 3.3vw;
    color: black;
    font-family: 'proxima-bold';
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 15px;
}
.abtPara3 {
    font-size: 2.3vw;
    color: black;
    font-family: 'proxima';
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 12px;
}
.getStart{
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid white;
    color: white;
    font-family: 'proxima-bold';
    font-size: 1.5vw;
    margin-bottom: 50px;
}
.getStart2{
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: black;
    color: white;
    font-family: 'proxima-bold';
    font-size: 1.5vw;
    width: 60%;
    margin-top: 35px;
}
.abtBtnCont {
    display: flex;
    justify-content: center;
    align-items: center;
}
.abtIm {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: #7b64ed33;
    background-image: url('../../assets/images/new_bg_about3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position:center-top;
    background-position-x: 50%;
    background-position-y: 0%; */
}
.zoomBack {
    animation: zoomin 10s 1;
    animation-fill-mode: forwards;
}
@keyframes zoomin {
    0% {
        -webkit-transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.2);
    }

}
.inWall {
    width: 20vw; 
    height: auto;
    margin-top: 30px; 
    margin-left: 50px;
}
.shrGr {
    width: 40vh;
    margin-top: -25px;
}
.calculatorSec {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7b64ed33;
    background-image: url('../../assets/images/calculator_bg_light.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 80px;
}
.calcCont {
    width: 45%;
    height: 70%;
    display: flex;
    flex-direction: column;
}
.doPrice {
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.foll {
    font-size: 2vw;
    font-family: 'proxima-bold';
    color: black;
}
.follInpCont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 30px;
    border-radius: 30px;
}
.follInp {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 30px;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.3vw;
}
.btnFol {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
input:focus{
    outline: none;
}
.lgo {
    height: 70px;
    margin-right: 20px;
}
.calcDone {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'proxima-semi-bold';
    font-size: 2vw;
    color: white;
}
.recCal {
    font-size: 1.5vw;
    margin-top: 20px;
    cursor: pointer;
    color: black;
}
.learnCalc {
    font-size: 20px;
    cursor: pointer;
}
.calcRes {
    font-size: 2vw;
    color: black;
}
.abtHead {
    font-size: 2.5vw;
    font-family: 'proxima-black';
    color: white;
    margin-bottom: 18px;
}
@media only screen and (max-width: 767px) {
    .midC{
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        z-index: 10;
        position: relative;
        font-family: 'proxima';
        margin-top: 18vh;
    }
    .mainText{
        font-size: 12vw;
        color: white;
        font-family: 'proxima-black';
        animation: slideIn 0.9s ease-in;
    }
    .signupCont {
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .learnM {
        margin-top: 20px;
        color: white;
        margin-left: 0;
        font-size: 5vw;
    }
    
    .secText{
        font-size: 7vw;
        color: white;
        font-family: 'proxima-bold';
        display: flex;
        line-height: 40px;
    }
    .signUpBtn{
        padding: 8px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        width: 100%;
        font-family: 'proxima-bold';
        font-size: 6vw;
        width: 100%;
    }
    .stepText {
        font-size: 6vw;
        color: white;
        font-family: 'proxima-black';
    }
    .numText {
        font-size: 3vw;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        height: 17px;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 5px;
        font-family: 'proxima-black';
    }
    .instaLogo{
        width: auto;
        height: 36px;
        margin-top: 5px;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        transform: scale(1.0); 
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/coke_bg_mobile.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        
    }
    .stepText {
        font-size: 6vw;
        color: white;
        font-family: 'proxima-black';
    }
    .numText {
        font-size: 5vw;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 35px;
        height: 35px;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 10px;
        font-family: 'proxima-black';
    }
    .rightC {
        font-family: 'proxima';
        background-color: rgba(255, 255, 255, 0.075);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 18vh;
        margin-bottom: 80px;
    }
    .landPic {
        width: 100%;
    }
    .abs{
        display: none;
    }
    .abs2{
        display: none;
    }
    .thirdStepC{
        padding-right: 50px;
        padding-left: 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        color: white;
        justify-content: space-between;
        height: 100%;
        margin-top: 10vh;
    }
    .purple {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -10vh;
    }
    .purple2 {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10vh;
    }
    .numText3{
        font-size: 5.5vw;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 35px;
        height: 35px;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 10px;
        font-family: 'proxima-black';
    }
    .stepText3 {
        font-size: 5.5vw;
        color: white;
        font-family: 'proxima-black';
    }
    .inWall {
        width: 100%;
        margin-right: 50px;
    }
    .step3Text {
        font-size: 5vw;
        color: white;
    }
    .abtSec {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }
    .abtCont {  
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
    }
    .abtPara {
        font-size: 7vw;
        color: white;
        font-family: 'proxima-semi-bold';
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 30px;
    }
    .abtPara2 {
        font-size: 8vw;
        color: black;
        font-family: 'proxima-bold';
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 15px;
    }
    .getStart{
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        font-family: 'proxima-bold';
        font-size: 5vw;
        margin-bottom: 50px;
        width: 100%;
    }
    .abtLgo {
        margin-top: 15px;
        height: 20vw;
        margin-bottom: 35px;
    }
    .abtLgo2 {
        margin-top: 15px;
        width: 50vw;
        margin-bottom: 35px;
    }
    .abtIm {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-color: #7b64ed33;
        background-image: url('../../assets/images/new_bg_about_mobile2_darker.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .mobMain {
        display: block;
        line-height: 30px;
    }
    .mobDesk {
        display: none;
    }
    .calcCont {
        width: 80%;
        height: 70%;
        display: flex;
        flex-direction: column;
    }
    .doPrice {
        margin-top: 30px;
        display: flex;
        align-items: center;
    }
    .foll {
        font-size: 5vw;
        font-family: 'proxima-bold';
        color: black;
    }
    .follInpCont {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        width: 80%;
        height: 30px;
    }
    .abtPara3 {
        font-size: 4.5vw;
        color: black;
        font-family: 'proxima';
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 20px;
    }
    .getStart2{
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: black;
        color: white;
        font-family: 'proxima-bold';
        font-size: 4vw;
        width: 100%;
    }
    .recCal {
        font-size: 4vw;
        margin-top: 20px;
        cursor: pointer;
    }
    .learnCalc {
        font-size: 15px;
        cursor: pointer;
    }
    .calcRes {
        font-size: 5vw;
    }
    .shrGr {
        width: 30vh;
        margin-top: -15px;
    }
    .abtHead {
        font-size: 8vw;
        font-family: 'proxima-black';
        color: white;
        margin-bottom: 18px;
    }
    .follInp {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        height: 25px;
        border-radius: 30px;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 4.1vw;
    }
  }
 


  @media screen and (min-width: 1800px) {
    .container{
        width: 100%;
        font-family: 'proxima';
        position: relative;
    }
    .midC{
        padding-right: 200px;
        padding-left: 200px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        z-index: 10;
        position: relative;
        font-family: 'proxima';
        margin-top: 25vh;
    }
    .signupCont {
        display: flex; 
        align-items: center;
    }
    .learnM {
        margin-left: 30px;
         color: white;
        font-size: 3vh;
    }
    .instaLogo{
        width: 10vw;
    }
    .mainText{
        font-size: 80px;
        color: white;
        font-family: 'proxima-black';
        animation: slideIn 0.9s ease-in;
        box-sizing: border-box;
    }
    .mobMain {
        display: none;
    }
    .mobDesk {
        display: block;
    }
    
    .secText{
        font-size: 40px;
        color: white;
        font-family: 'proxima-bold';
        display: flex;
    }
    .signUpBtn{
        padding: 10px 50px 10px 50px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        width: auto;
        font-family: 'proxima-bold';
        font-size: 30px;
    }
    .stepText {
        font-size: 32px;
        color: white;
        font-family: 'proxima-black';
    }
    .landPic {
        width: 30vh;
    }
    .numText {
        font-size: 1.2vw;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 10px;
        font-family: 'proxima-black';
    }
    .insta{
        position: relative;
    }
    .arr{
        width: 35vw; 
        transform: scaleX(-1);
        rotate: 10deg;
    
    }
    .abs{
        position: absolute;
        bottom: -55vh;
        right: 28vw;
        z-index: -10;
    }
    .arr2{
        width: 30vw; 
        /* transform: scaleX(-1);
        filter: 'FlipH'; */
        rotate: 20deg;
        transform: scaleY(-1);
    }
    .abs2{
        position: absolute;
        top: 50px;
        left: 50vh;
    }
    .thirdStepC{
        padding-right: 200px;
        padding-left: 200px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: white;
        justify-content: space-between;
        margin-top: 20vh;
    
    }
    .numText3{
        font-size: 1.2vw;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 35px;
        height: 35px;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 10px;
        font-family: 'proxima-black';
    }
    .stepText3 {
        font-size: 30px;
        color: white;
        font-family: 'proxima-black';
    }
    .overlay {
        object-fit: contain;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 
        background-image: url('../../assets/images/coke_bg_darker.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    .rightC {
        font-family: 'proxima';
        background-color: rgba(255, 255, 255, 0.075);
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
    }
    .step3Text {
        font-size: 30px;
        color: white;
    }
    .purple {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 50vh;

    }
    .purple2 {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        width: 30vw;
    }
    .abtSec {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }
    .abtCont {  
        /* background-color: rgba(0, 0, 0, 0.8); */
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 50%;
        height: 70%;
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
        padding-bottom: 60px;
        padding-top: 50px;
        margin-top: 70px;
    }
    .lgo {
        height: 90px;
        margin-bottom: 30px;
    }
    .abtText {
        font-size: 30pxvw;
        color: white;
        font-family: 'proxima-bold';
    }
    .abtLgo {
        margin-top: 15px;
        height: 200px;
        margin-bottom: 35px;
    }
    .abtLgo2 {
        margin-top: 15px;
        width: 20vw;
        margin-bottom: 35px;
    }
    .abtPara {
        font-size: 40px;
        color: white;
        font-family: 'proxima-semi-bold';
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
    
    }
    .abtPara2 {
        margin-bottom: 60px;
        font-size: 3.4vw;
        color: black;
        font-family: 'proxima-bold';
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 15px;
    
    }
    .getStart{
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        font-family: 'proxima-bold';
        font-size: 1.5vw;
        margin-bottom: 50px;
    }
    .abtBtnCont {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .abtIm {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-color: #7b64ed33;
        background-image: url('../../assets/images/new_bg_about3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        /* background-position:center-top;
        background-position-x: 50%;
        background-position-y: 0%; */
    }
    .zoomBack {
        animation: zoomin 10s 1;
        animation-fill-mode: forwards;
    }
    @keyframes zoomin {
        0% {
            -webkit-transform: scale(1);
        }
        100% {
            -webkit-transform: scale(1.2);
        }
    
    }
    .inWall {
        width: 30vh; 
        height: auto;
        margin-top: 30px; 
        margin-left: 50px;
    }
    .shrGr {
        width: 30vh;
        margin-top: -15px;
    }
  }

  @media screen and (min-width: 2000px) {
    .container{
        width: 100%;
        font-family: 'proxima';
        position: relative;
    }
    .midC{
        padding-right: 300px;
        padding-left: 300px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        z-index: 10;
        position: relative;
        font-family: 'proxima';
        margin-top: 25vh;
    }
    .signupCont {
        display: flex; 
        align-items: center;
    }
    .learnM {
        margin-left: 30px;
         color: white;
        font-size: 3vh;
    }
    .instaLogo{
        width: 8vw;
    }
    .mainText{
        font-size: 80px;
        color: white;
        font-family: 'proxima-black';
        animation: slideIn 0.9s ease-in;
        box-sizing: border-box;
    }
    .mobMain {
        display: none;
    }
    .mobDesk {
        display: block;
    }
    
    .secText{
        font-size: 40px;
        color: white;
        font-family: 'proxima-bold';
        display: flex;
    }
    .signUpBtn{
        padding: 10px 50px 10px 50px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        width: auto;
        font-family: 'proxima-bold';
        font-size: 30px;
    }
    .stepText {
        font-size: 32px;
        color: white;
        font-family: 'proxima-black';
    }
    .landPic {
        width: 35vh;
    }
    .numText {
        font-size: 1.3vw;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 10px;
        font-family: 'proxima-black';
    }
    .insta{
        position: relative;
    }
    .arr{
        width: 30vw; 
        transform: scaleX(-1);
        rotate: 10deg;
    
    }
    .abs{
        position: absolute;
        bottom: -40vh;
        right: 60vh;
        z-index: -10;
    }
    .arr2{
        width: 30vw; 
        /* transform: scaleX(-1);
        filter: 'FlipH'; */
        rotate: 20deg;
        transform: scaleY(-1);
    }
    .abs2{
        position: absolute;
        top: -30px;
        left: 45vh;
    }
    .thirdStepC{
        padding-right: 300px;
        padding-left: 300px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: white;
        justify-content: space-between;
        margin-top: 20vh;
    
    }
    .numText3{
        font-size: 25px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 35px;
        height: 35px;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 10px;
        font-family: 'proxima-black';
    }
    .stepText3 {
        font-size: 30px;
        color: white;
        font-family: 'proxima-black';
    }
    .overlay {
        object-fit: contain;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 
        background-image: url('../../assets/images/coke_bg_darker.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    .rightC {
        font-family: 'proxima';
        background-color: rgba(255, 255, 255, 0.075);
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        width: auto;
    }
    .step3Text {
        font-size: 30px;
        color: white;
    }
    .purple {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 50vh;

    }
    .purple2 {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        width: 40%;
    }
    .abtSec {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }
    .abtCont {  
        /* background-color: rgba(0, 0, 0, 0.8); */
        padding: 30px;
        box-sizing: border-box;
        border-radius: 25px;
        width: 50%;
        height: 70%;
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;
        padding-bottom: 60px;
        padding-top: 50px;
        margin-top: 70px;
    }
    .lgo {
        height: 90px;
        margin-bottom: 30px;
    }
    .abtText {
        font-size: 30vw;
        color: white;
        font-family: 'proxima-bold';
    }
    .abtLgo {
        margin-top: 15px;
        height: 18vw;
        margin-bottom: 35px;
    }
    .abtLgo2 {
        margin-top: 15px;
        width: 20vw;
        margin-bottom: 35px;
    }
    .abtPara {
        font-size: 40px;
        color: white;
        font-family: 'proxima-semi-bold';
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 35px;
    }
    .abtPara2 {
        margin-bottom: 30px;
        font-size: 3.4vw;
        color: black;
        font-family: 'proxima-bold';
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 15px;
    
    }
    .getStart{
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        font-family: 'proxima-bold';
        font-size: 1.5vw;
        margin-bottom: 50px;
    }
    .abtBtnCont {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .abtIm {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-color: #7b64ed33;
        background-image: url('../../assets/images/new_bg_about3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        /* background-position:center-top;
        background-position-x: 50%;
        background-position-y: 0%; */
    }
    .zoomBack {
        animation: zoomin 10s 1;
        animation-fill-mode: forwards;
    }
    @keyframes zoomin {
        0% {
            -webkit-transform: scale(1);
        }
        100% {
            -webkit-transform: scale(1.2);
        }
    
    }
    .inWall {
        width: 30vh; 
        height: auto;
        margin-top: 30px; 
        margin-left: 50px;
    }
    .shrGr {
        width: 40vh;
        margin-top: -33px;
    }
    .learnCalc {
        font-size: 30px;
        cursor: pointer;
    }
    .follInpCont {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        width: 80%;
        height: 40px;
    }
    .follInp {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-size: 1vw;
        height: 35px;
    }
    .doPrice {
        margin-top: 50px;
        display: flex;
        align-items: center;
    }
    .btnFol {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 95px;
    }
    .getStart2{
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: black;
        color: white;
        font-family: 'proxima-bold';
        font-size: 1.5vw;
        margin-bottom: 50px;
        width: 60%;
    }
    .follInp {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        height: 40px;
        border-radius: 30px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.2vw;
    }
  }