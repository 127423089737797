.modalCont {
    width: 25%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    font-family: 'proxima';
    overflow: hidden;
    background-color: white;
}
.btnCont {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
}
.shareText {
    font-family: 'proxima-semi-bold';
    font-size: 1.8vw;
    margin-bottom: 25px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}
@media only screen and (max-width: 767px) {
    .modalCont {
        width: 100%;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        align-items: center;
        font-family: 'proxima';
        overflow: hidden;
    }
    .shareText {
        font-family: 'proxima';
        font-size: 5vw;
        margin-bottom: 20px; 
        display: flex; 
        justify-content: center; 
        align-items: center;
    }
}