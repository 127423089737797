.topCC {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.container {
    background-color: #fbf7f3;
    width: 430px;
    height: 480px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.blackCont {
    background-color: black;
    width: 350px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.imageToShowCont {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.imgToShow {
    width: 100%;
    position: absolute;
}

.postBtn {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 18px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'proxima';
    background-color: white;
    color: black;
    font-size: 13px;
}
.inp {
    all: unset;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.443);
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    background-color: white;
}
.titleText {
    color: white;
    font-family: 'proxima-bold';
    font-size: 2.5vw;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    margin-left: 120px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
}
.arr{
    width: 300px; 
    /* transform: scaleX(-1);
    filter: 'FlipH'; */
}
.abs{
    position: absolute;
    top: -160px;
    left: 320px;
}
.polCont {
    width: 28vw;
    height: 70vh;
    background-color: #fbf7f3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.whiteCont {
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fbf7f3;
}
.bCont {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}
.txtWrapper {
    padding-left: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.close {
    position: absolute;
    top: 0px;
    right: 0px;
    color: black;
    cursor: pointer;
    z-index: 12;
}
.imPlace {
    width: 4vw;
}
.emjArr {
    font-size: 3.8vw;
}
@media only screen and (max-width: 767px) {
    .topCC {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
    }
    .postBtn {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima';
        background-color: white;
        color: black;
        font-size: 13px;
    }
    .inp {
        all: unset;
        padding: 10px;
        border: 2px solid rgba(0, 0, 0, 0.443);
        box-sizing: border-box;
        width: 100%;
        border-radius: 5px;
        background-color: white;
    }
    .imPlace {
        width: 10vw;
    }
    .titleText {
        color: white;
        font-family: 'proxima-bold';
        font-size: 6vw;
        display: flex;
        position: relative;
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
    }
    .arr{
        width: 300px; 
        /* transform: scaleX(-1);
        filter: 'FlipH'; */
        display: none;
    }
    .abs{
        position: absolute;
        top: -160px;
        left: 320px;
        display: none;
    }
    .polCont {
        width: 100%;
        height: 60vh;
        background-color: #fbf7f3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .whiteCont {
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fbf7f3;
    }
    .bCont {
        background-color: black;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .txtWrapper {
        padding-left: 20px; 
        padding-right: 20px; 
        padding-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .imgToShow {
        width: 100%;
        position: absolute;
    }
    .imageToShowCont {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .emjArr {
        font-size: 10vw;
    }
}