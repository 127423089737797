.modalCont {
    width: 35%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 50px 30px 50px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    font-family: 'proxima';
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
}
.approveBtn {
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    font-family: 'proxima-semi-bold';
    margin-bottom: 10px;
    background-color: black;
    color: white;
    margin-top: 15px;
    font-size: 1.4vw;
}
.infoDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.placeCont {
    width: 15vw;
    height: 15vw;
    border-radius: 10px;
    background-color: #EEEEEE;
    cursor: pointer;
}
.adIm {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.adImModal {
    object-fit: cover;
    height: 20vw;
    width: 100%;
}
.infText {
    font-family: 'proximasemi-bold';
    font-size: 1.4vw;
}
.tags {
    padding: 10px 20px 10px 20px;
    box-sizing: border-box;
    background-color: #bcbcbc;
    border-radius: 18px;
    font-size: 14px;
    font-family: 'proxima-semi-bold';
}
.tagDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.chek {
    width: 20px;
    margin-left: 10px;
}
.reject {
    border: 1px solid black;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.downloadBtn {
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50%;
    font-family: 'proxima-semi-bold';
    margin-bottom: 5px;
    background-color: #ebebeb;
    color: black;
    margin-top: 15px;
    font-size: 1vw;
}
.signUpBtn{
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 60%;
    font-family: 'proxima-semi-bold';
    margin-bottom: 10px;
    background-color: black;
    color: white;
    margin-top: 20px;
    font-size: 1.1vw;
}
.dl {
    width: 0.9vw;
    height: auto;
    margin-left: 10px;
}
.playIcDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 767px) {
    .placeCont {
        width: 40vw;
        height: 40vw;
        border-radius: 10px;
        background-color: #EEEEEE;
        cursor: pointer;
    }
    .adIm {
        width: 70vw;
        object-fit: cover;
    }
    .adImModal {
        height: 40vh;
        object-fit: cover;
        width: 100%;
    }
    .modalCont {
        width: 100%;
        height: auto;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 15px 30px 15px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        align-items: center;
        font-family: 'proxima';
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: black;
        cursor: pointer;
    }
    .approveBtn {
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        margin-bottom: 10px;
        background-color: black;
        color: white;
        margin-top: 15px;
        font-size: 4vw;
    }
    .downloadBtn {
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 50%;
        font-family: 'proxima-semi-bold';
        margin-bottom: 5px;
        background-color: #ebebeb;
        color: black;
        margin-top: 15px;
        font-size: 3vw;
    }
    .infText {
        font-family: 'proximasemi-bold';
        font-size: 4vw;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        font-size: 4vw;
        margin-top: 20px;
    }
    .dl {
        width: 3.1vw;
        height: auto;
        margin-left: 10px;
    }
}
@media screen and (min-width: 2000px) {
    .modalCont {
        width: 35%;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 50px 30px 50px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        align-items: center;
        font-family: 'proxima';
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: black;
        cursor: pointer;
    }
    .approveBtn {
        padding: 10px;
        border-radius: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        margin-bottom: 10px;
        background-color: black;
        color: white;
        margin-top: 15px;
        font-size: 1.4vw;
    }
    .infoDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .placeCont {
        width: 15vw;
        height: 15vw;
        border-radius: 10px;
        background-color: #EEEEEE;
        cursor: pointer;
    }
    .adIm {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .adImModal {
        object-fit: cover;
        height: 20vw;
        width: 100%;
    }
    .infText {
        font-family: 'proximasemi-bold';
        font-size: 1.4vw;
    }
    .tags {
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
        background-color: #bcbcbc;
        border-radius: 18px;
        font-size: 14px;
        font-family: 'proxima-semi-bold';
    }
    .tagDiv {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .chek {
        width: 20px;
        margin-left: 10px;
    }
    .reject {
        border: 1px solid black;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .downloadBtn {
        padding: 10px;
        border-radius: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 50%;
        font-family: 'proxima-semi-bold';
        margin-bottom: 5px;
        background-color: #ebebeb;
        color: black;
        margin-top: 15px;
        font-size: 1vw;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 60%;
        font-family: 'proxima-semi-bold';
        margin-bottom: 10px;
        background-color: black;
        color: white;
        margin-top: 20px;
        font-size: 1.2vw;
    }
    .dl {
        width: 0.8vw;
        height: auto;
        margin-left: 10px;
    }
}