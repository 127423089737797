.container {
    width: 100%;
    background: #f3f6f4;
    height: 'auto';
    display: flex;
    flex-direction: column;
    font-family: 'proxima';
    overflow: hidden;
}
.header {
    display: flex;
    margin-top: 120px;
    padding-left: 150px;
    padding-right: 150px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}
.profileCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .profilePicture {
    width: 100px; 
    height: 100px; 
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
  }
  
  .profilePicture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }
  .picWrap {
    padding: 1px;
    position: relative;

  }
  .username {
    margin-top: 10px;
    color: black;
    font-size: 1.8vw;
    font-family: 'proxima-black';
  }
  .titleText {
    color: black;
    font-size: 25px;
    margin-bottom: 20px;
    border-bottom: 0.6px solid black;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
  .pending {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
    font-family: 'proxima-semi-bold';
  }
  .posted {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    font-family: 'proxima-semi-bold';
  }
  .appText {
    font-size: 2vw;
  }
  .adCon {
    padding-left: 150px;
    padding-right: 150px;
    box-sizing: border-box;
  }
  .settings{
    padding-left: 150px;
    padding-right: 150px;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .adProp{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* border: 2px solid black; */
    color: white;
    font-family: 'proxima-semi-bold';
    font-size: 1.3vw;
    background: rgb(131,83,215);
    background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
}
.prpLearn {
  position: absolute;
  right: -30px;
  z-index: 99;
  bottom: 10px;
  cursor: pointer;
  display: block;
}
.prpLearnMob {
  position: absolute;
  right: -30px;
  z-index: 99;
  bottom: 10px;
  cursor: pointer;
  display: none;
}
.secButn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 1vw;
  background-color: black;
}
.payDisabled {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 1vw;
  background-color: black;
  opacity: 0.4;
  pointer-events: none;
}
.secButnSet {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 1.4vw;
  background-color: black;
  width: 100%
}
.metric {
  color: black;
  font-size: 1.3vw;
  display: flex;
  align-items: center;
  font-family: 'proxima-semi-bold';
}
.mIcons {
  font-size: 30px;
}
.headerCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.extraText{
  color: black;
  font-size: 25px;
  box-sizing: border-box;
}
.share {
  margin-top: 20px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEBEB;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  font-size: 1.5vw;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.link {
  font-family: 'proxima-semi-bold';
}
.upper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.lower {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.loading {
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  padding: 20px;
  box-sizing: border-box;
  width: 35vw;
  height: 35vh;
  max-height: 35vh;
  display: flex;
  border-radius: 18px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
  background-color: #eeeeee;
}
.cardPass {
  padding: 20px;
  box-sizing: border-box;
  width: 35vw;
  height: 35vh;
  max-height: 35vh;
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
  background-color: #eeeeee;
}
.inpPr {
  all: unset;
  padding: 10px;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  width: 100%;
  margin-left: 5px;
  font-size: 1vw;
}
.inp {
  all: unset;
  padding: 10px;
  border-bottom: 0.8px solid black;
  box-sizing: border-box;
  margin-top: 10px;
  width: 100%;
  font-size: 1vw;
}
.sh {
  width: 1.7vw;
}
.edit {
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 20;
  width: 10px;
  height: 10px;
}
.stripeText {
  display: flex;
  align-items: center;
  font-family: "proxima-bold";
  font-size: 1.5vw;
}
.stLogo {
  width: 4vw;
} 
.adPropMob{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 4vw;
  background: rgb(131,83,215);
  background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
  display: none;
  position: relative;
}
.chgPass {
  font-size: 1.35vw;
}
.priceText {
  font-size: 1.25vw;
}
@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
    background: #f3f6f4;
    height: 'auto';
    display: flex;
    flex-direction: column;
    font-family: 'proxima';
    overflow: hidden;
}
.header {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: flex-start;
}
.profileCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: center;
  }
  
  .profilePicture {
    width: 80px; 
    height: 80px; 
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .profilePicture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .username {
    color: black;
    font-size: 5vw;
    font-family: 'proxima-black';
  }
  .titleText {
    color: black;
    font-size: 25px;
    margin-bottom: 20px;
    border-bottom: 0.6px solid black;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
  .appText {
    font-size: 5vw;
  }
  .pending {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
    font-family: 'proxima-semi-bold';
  }
  .posted {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    font-family: 'proxima-semi-bold';
  }
  .adCon {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .settings{
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
  }
  .adProp{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* border: 2px solid black; */
    color: white;
    font-family: 'proxima-semi-bold';
    font-size: 4vw;
    background: rgb(131,83,215);
    background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
    display: none;
}
.adPropMob{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 4vw;
  background: rgb(131,83,215);
  background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
  display: block;
}
.secButn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 4vw;
  background-color: black;
  width: 55%;
}
.payDisabled {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 4vw;
  background-color: black;
  width: 55%;
  opacity: 0.4;
  pointer-events: none;
}
.secButnSet {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 2px solid black; */
  color: white;
  font-family: 'proxima-semi-bold';
  font-size: 4vw;
  background-color: black;
  width: 100%;
}
.metric {
  color: black;
  font-size: 4vw;
  display: flex;
  align-items: center;
  font-family: 'proxima-semi-bold';
  margin-top: 10px;
}
.metCont {
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: #eeeeee;
  border-radius: 10px;
  width: 100%;
}
.mIcons {
  font-size: 20px;
}
.headerCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  width: 100%;
}
.extraText{
  color: black;
  font-size: 23px;
  box-sizing: border-box;
}
.share {
  margin-top: 20px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEBEB;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 3.3vw;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.link {
  font-family: 'proxima-semi-bold';
}
.upper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}
.lower {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.loading {
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border-radius: 18px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
  background-color: #eeeeee;
}
.cardPass {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border-radius: 18px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
  background-color: #eeeeee;
  margin-top: 20px;
}
.inpPr {
  all: unset;
  padding: 10px;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  width: 100%;
  margin-left: 5px;
}
.inp {
  all: unset;
  padding: 10px;
  border-bottom: 0.8px solid black;
  box-sizing: border-box;
  margin-top: 10px;
  width: 100%;
}
.sh {
  width: 1.7vw;
}
/* .edit {
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 300;
} */
.stripeText {
  display: flex;
  align-items: center;
  font-family: "proxima-bold";
  font-size: 4vw;
}
.stLogo {
  width: 10vw;
}
.mobHead {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
 justify-content: center;
 align-items: center;
}
.prpLearn {
  position: absolute;
  right: -30px;
  z-index: 99;
  bottom: 10px;
  cursor: pointer;
  display: none;
}
.prpLearnMob {
  position: absolute;
  right: -30px;
  z-index: 99;
  bottom: 10px;
  cursor: pointer;
  display: block;
}
.chgPass {
  font-size: 6vw;
}
.priceText {
  font-size: 4vw;
}
}