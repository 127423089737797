.container {
    width: 100%;
    height: 100vh;
    font-family: 'proxima';
    background-image: url('../../assets/images/coke_bg_darker_taller3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.topC {
    width: 100%;
    height: 60%;
    position: relative;
}
.formC {
    width: 25%;
    height: 50vh;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
}
.abs {
    position: absolute;
    width: 100%;
    height: 90%;
    bottom: -140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 12;
}
.signUpBtn{
    padding: 10px;
    border-radius: 22px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    font-family: 'proxima-semi-bold';
    background-color: black;
    color: white;
    margin-top: 40px;
    font-size: 1.3vw;
}
.innerF {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.inp {
    all: unset;
    padding: 10px;
    border-bottom: 1px solid black;
    box-sizing: border-box;
    width: 100%;
    margin-left: 5px;
}
.welc {
    font-family: 'proxima-bold';
    font-size: 1.7vw;
}
.priceText {
    font-family: 'proxima';
}
.overlay {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .lgo {
    height: 80px;
    margin-bottom: 16px;
}
.skipF {
    font-size: 1.1vw;
}
@media only screen and (max-width: 767px) {
    .container {
        width: 100%;
        height: 100vh;
        font-family: 'proxima';
        background-image: url('../../assets/images/coke_bg_mobile.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        background-position: center;
    }
    .topC {
        width: 100%;
        height: 60%;
        position: relative;
    }
    .formC {
        width: 70%;
        height: 50vh;
        background-color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
    }
    .abs {
        position: absolute;
        width: 100%;
        height: 90%;
        bottom: -140px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        z-index: 12;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 22px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        margin-top: 40px;
        font-size: 4vw;
    }
    .innerF {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .inp {
        all: unset;
        padding: 10px;
        border-bottom: 1px solid black;
        box-sizing: border-box;
        width: 100%;
        margin-left: 5px;
    }
    .welc {
        font-family: 'proxima-bold';
        font-size: 4vw;
    }
    .priceText {
        font-family: 'proxima';
    }
    .overlay {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .lgo {
        height: 80px;
        margin-bottom: 16px;
    }
    .skipF {
        font-size: 3.3vw;
    }
}

@media screen and (min-width: 2000px) {
    .container {
        width: 100%;
        height: 100vh;
        font-family: 'proxima';
        background-image: url('../../assets/images/coke_bg_darker_taller3.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .topC {
        width: 100%;
        height: 60%;
        position: relative;
    }
    .formC {
        width: 25%;
        height: 35vh;
        background-color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
    }
    .abs {
        position: absolute;
        width: 100%;
        height: 90%;
        bottom: -140px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        z-index: 12;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 22px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        margin-top: 40px;
        font-size: 1.3vw;
    }
    .innerF {
        display: flex;
        flex-direction: column;
        padding: 30px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .inp {
        all: unset;
        padding: 10px;
        border-bottom: 1px solid black;
        box-sizing: border-box;
        width: 100%;
        margin-left: 5px;
    }
    .welc {
        font-family: 'proxima-bold';
        font-size: 1.7vw;
    }
    .priceText {
        font-family: 'proxima';
    }
    .overlay {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .lgo {
        height: 80px;
        margin-bottom: 16px;
    }
}