@font-face {
  font-family: 'proxima';
  src: url('../src/styles/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proxima-bold';
  src: url('../src/styles/fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'proxima-black';
  src: url('../src/styles/fonts/ProximaNova-Black.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'proxima-semi-bold';
  src: url('../src/styles/fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'proxima-extra-bold';
  src: url('../src/styles/fonts/ProximaNova-Extrabold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'billa';
  src: url('../src/styles/fonts/FontsFree-Net-Billabong.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'insta';
  src: url('../src/styles/fonts/InstagramSansHeadline-Regular.ttf') format('truetype');
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;

}
