.container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10vw;
    padding-left: 10vw;
    box-sizing: border-box;
    font-family: 'proxima';
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 122;
}
.containerDash {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10vw;
    padding-left: 10vw;
    box-sizing: border-box;
    font-family: 'proxima';
    position: absolute;
    z-index: 12;
    transition: top 0.3s;
    background-color: black;
    padding-top: 15px;
    padding-bottom: 15px;
}
.leftDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.rightDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.rightDivDash{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.loginBtn{
    padding: 10px 35px 10px 35px;
    border-radius: 35px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid white;
    color: white;
    font-size: 1.3vw;
}
.lgo {
    height: 50px;
}
.lgoHam {
    height: 45px;
}
.abtTe {
    color: white;
    font-family: 'proxima-semi-bold';
    font-size: 1.5vw;
    margin-left: 50px;
    margin-bottom: 9px;
}
.menu1 {
    display: none;
    border: 1px solid rgb(255, 255, 255);
    width: 28px;
    transition: transform 0.3s ease-out;
  }
  .menu2 {
    display: none;
    border: 1px solid rgb(255, 255, 255);
    width: 23px;
    transition: transform 0.4s ease-out;
    margin-top: 5px;
  }
  .items:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
.menuIt {
    height: 20px;
    display: flex;
    flex-direction: column; 
    z-index: 999;   
    position: absolute;
    top: 20px;
    right: 20px;
}
.anim1 {
    transform: rotate(-45deg);
    border: 1px solid rgb(0, 0, 0);
}
.anim2 {
    transform: rotate(45deg);
    margin-top: 0px;
    width: 28px;
    border: 1px solid rgb(0, 0, 0);
}
.hamburgerMenu {
    height: 70px;
    width: 100%;
    position: absolute;
    top: -20px;
    right: 20px;
    display: none;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 9;
    transition: top 0.3s;
}
.hamWrapper {
    display: none;
    position: absolute;
    top: -30px;
    left: 20px;
    transform: translate(500px);
    background-color: white;
    height: 110vh;
}
@keyframes slideIn {
    from {
        transform: translate(500px);
        display: flex;

    }
    to {
        transform: translate(0);
    }
}
.hamIn {
    animation: slideIn 0.3s ease-in both;
}
.hamIt {
    font-size: 25px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    font-family: 'proxima-semi-bold';
}
@media (max-width: 768px) {
    .container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;
        padding-left: 30px;
        box-sizing: border-box;
        font-family: 'proxima';
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 12;
    }
    .containerDash{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;
        padding-left: 30px;
        box-sizing: border-box;
        font-family: 'proxima';
        position: absolute;
        z-index: 12;
        transition: top 0.3s;
        background-color: black;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .loginBtn{
        padding-right: 16px;
        padding-left: 16px;
        border-radius: 35px;
        padding-top: 7px;
        padding-bottom: 7px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        font-size: 15px;
    }
    .loginBtnDash {
        padding-right: 18px;
        padding-left: 18px;
        border-radius: 35px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid black;
        color: black;
        font-size: 15px;
    }
    .lgo {
        height: 33px;
    }
    .lgoHam {
        margin-bottom: 150px;
        height: 45px;
    }
    .abtTe {
        color: white;
        font-family: 'proxima';
        font-size: 4vw;
        margin-left: 15px;
        margin-bottom: 9px;
        display: none;
    }
    .leftDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .rightDiv{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .rightDivDash{
        display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .hamburgerMenu {
        padding-left: 5%;
        padding-right: 5%;
        z-index: 9999;
        display: block;
    }
    .menu1 {
        display: block;
    }
    .menu2 {
        display: block;
    }
    .hamWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 110vh;
        box-sizing: border-box;
        padding: 30px;
        justify-content: space-between;
        align-items: center;
    }
}
.loginBtnDash {
    padding: 10px 35px 10px 35px;
    border-radius: 35px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid black;;
    color: black;
    font-size: 1.3vw;
}
