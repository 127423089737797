.p-curve {
    padding: 0;
}
.container {
    font-family: 'proxima';
    width: 100%;
    height: 100vh;
    position: relative;
    
}
.topC{
    width: 100%;
    height: 100%;
}
.leftC {
   
}
.midC{
    padding-right: 10vw;
    padding-left: 10vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
.mainText{
    font-size: 4vw;
    color: white;
    font-family: 'proxima-black';
    box-sizing: border-box;
    display: flex;
}
.secText{
    font-size: 2.3vw;
    color: white;
    font-family: 'proxima-bold';
}
.signUpBtn{
    padding: 10px;
    border-radius: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid white;
    color: white;
    width: 200px;
    font-family: 'proxima-bold';
    font-size: 1.5vw;
}
.rightC{

}
.bulb {
    width: 18vw;
}
.usName {
    color: white;
    font-size: 2vw;
    font-family: 'proxima-semi-bold';
}
.rC {
    display: flex;
    flex-direction: column;
}
.joinWText {
    font-family: 'proxima-semi-bold';
    font-size: 1.7vw;
}
.transCont {
    background-color: rgba(255, 255, 255, 0.075);
    padding: 15px;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.urlPr {
    color: white;
    font-size: 1.4vw;
}
.checkM {
    margin-top: 10px;
    color: white;
    font-size: 1.3vw;
    font-family: 'proxima-semi-bold';
    display: flex;
    align-items: center;
}
.hrt {
    position: absolute;
    bottom: 0;
    left: 7vw;
    width: 2.5vw;
}
.stepText {
    font-size: 1.5vw;
    color: white;
}
.numText {
    font-size: 1.5vw;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 0.4px solid white;
    border-radius: 50%;
    margin-right: 10px;
}
.insta{
    position: relative;
}
.secCont{
    width: 100%;
    padding-left: 200px;
    padding-right: 200px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.info {
    margin-top: 80px;
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin-bottom: 30px;
    color: white;
}
.postBtn {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'proxima-semi-bold';
    margin-top: 30px;
    margin-bottom: 50px;
    background-color: black;
    color: white;
    padding: 10px 35px 10px 35px;
    border-radius: 35px;
    font-size: 1.5vw;
}
.modalCont {
    width: 35%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    font-family: 'proxima';
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
}
.inp {
    all: unset;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.443);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    margin-top: 30px;
}
.postBtn2 {
    padding: 10px;
    border-radius: 18px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 200px;
    font-family: 'proxima-semi-bold';
    font-size: 1.3vw;
    margin-bottom: 30px;
    background-color: black;
    color: white;
    width: 100%;
    margin-top: 30px;
}
.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.7vw;
    font-family: 'proxima-bold';
}
.money {
    margin-top: 10px;
    font-family: 'proxima-semi-bold';
}
.disabled {
    opacity: 0.3;
    pointer-events: none;
}
.rd {
    margin-right: 10px;
}
.overlay {
    object-fit: contain;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
    background-image: url('../../assets/images/coke_bg_darker.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.agree {
    margin-top: 20px;
}
.ruleText {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5vw;
}
.ruleCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.subTit {
    margin-top: 10px;
    font-family: 'proxima-bold';
    font-size: 1.6vw;
    margin-bottom: 10px;
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

.instaLogo{
    width: 18vw;
    margin-top: 10px;    
}
.loading {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .confirmCont {
    display: flex;
    flex-direction: column;
  }
  .congText {
    font-family: 'proxima-bold';
    font-size: 1.6vw;
    margin-top: 10px;
  }
  .signupContDesk {
    display: flex; 
    align-items: center;
}
.learnM {
    margin-left: 35px;
    color: white;
    font-size: 1.5vw;
    cursor: pointer;
}
.urlStuff {
    display: flex;
    align-items: center;
}
.shareCont {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 .signupContMob {
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;
}
.finger {
    font-size: 2vw; 
    margin-right: 10px; 
}
.leftCMob {
    display: none;
}
.rightCDesk {
    display: block;
}
.sendText {
    font-size: 1.4vw;
}
.profPicCont {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
}
.profPicCont img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
.checkEmj {
    width: 20vw;
}
  @media only screen and (max-width: 767px) {
    .topC{
        width: 100%;
        height: 100%;
    }
    .midC{
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70%;
        width: 100%;
        box-sizing: border-box;
    }
    .sendText {
        font-size: 4vw;
    }
    .leftC {
        display: none;
    }
    .leftCMob {
        display: block;
    }
    .joinWText {
        font-family: 'proxima-semi-bold';
        font-size: 5vw;
    }
    .mainText{
        font-size: 8vw;
        color: white;
        font-family: 'proxima-black';
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
    .secText{
        font-size: 5.5vw;
        color: white;
        font-family: 'proxima-bold';
        margin-top: 15px;
    }
    .instaLogo{
        width: 35vw;
        margin-top: 5px;    
    }
    .congText {
        font-family: 'proxima-bold';
        font-size: 5vw;
        margin-top: 10px;
      }
    .signUpBtn{
        padding: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        width: 100%;
        font-family: 'proxima-bold';
        font-size: 6vw;
    }
    .rightC{
       
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        transform: scale(1.0); 
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/coke_bg_mobile.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }   
    }
    .signupContDesk {
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: none;
    }
    .signupContMob {
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
    }
    .learnM {
        margin-top: 50px;
        color: white;
        margin-left: 0px;
        font-size: 5vw;
    }
    .info {
        margin-top: 40px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        width: 90%;
        margin-bottom: 30px;
        color: white;
    }
    .secCont{
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 0px;
    }
    .options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 5vw;
        font-family: 'proxima-bold';
        margin-top: 10px;
    }
    .money {
        margin-top: 0px;
        font-family: 'proxima-semi-bold';
    }
    .postBtn {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        margin-top: 30px;
        margin-bottom: 80px;
        background-color: black;
        color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 35px;
        font-size: 4vw;
        width: 100%;
    }
    .bulb {
        width: 45vw;
    }
    .usName {
        color: white;
        font-size: 4.2vw;
        font-family: 'proxima-semi-bold';
    }
    .rC {
        display: flex;
        flex-direction: column;
    }
    .transCont {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 5px 15px 5px 15px;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        margin-top: 10px;
        width: 100%;
    }
    .shareCont {
       color: white;
    }
    .urlStuff {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .urlPr {
        color: white;
        font-size: 4vw;
    }
    .checkM {
        margin-top: 10px;
        color: white;
        font-size: 2.5vw;
        font-family: 'proxima-semi-bold';
        display: flex;
        align-items: center;
    }
    .hrt {
        position: absolute;
        bottom: 0;
        left: 20vw;
        width: 6vw;
    }
    .modalCont {
        width: 100%;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        align-items: center;
        font-family: 'proxima';
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: black;
        cursor: pointer;
    }
    .subTit {
        margin-top: 10px;
        font-family: 'proxima-bold';
        font-size: 4vw;
        margin-bottom: 10px;
    }
    .ruleText {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4vw;
    }
    .finger {
        font-size: 6vw; 
        margin-right: 10px; 
    }
    .postBtn2 {
        padding: 10px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 200px;
        font-family: 'proxima-semi-bold';
        font-size: 4vw;
        margin-bottom: 30px;
        background-color: black;
        color: white;
        width: 100%;
        margin-top: 30px;
    }
    .mobCont {
        display: flex;
    }
    .rightCDesk {
        display: none;
    }
    .profPicCont {
        max-width: 100px;
        max-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
    }
}
@media screen and (min-width: 2000px) {
    .container {
        font-family: 'proxima';
        width: 100%;
        height: 100vh;
        position: relative;
        
    }
    .topC{
        width: 100%;
        height: 100%;
    }
    .leftC {
       
    }
    .midC{
        padding-right: 10vw;
        padding-left: 10vw;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .mainText{
        font-size: 4vw;
        color: white;
        font-family: 'proxima-black';
        box-sizing: border-box;
        display: flex;
    }
    .secText{
        font-size: 2.3vw;
        color: white;
        font-family: 'proxima-bold';
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        color: white;
        width: auto;
        font-family: 'proxima-bold';
        font-size: 1.5vw;
        padding: 10px 50px 10px 50px;
    }
    .rightC{
    
    }
    .bulb {
        width: 15vw;
    }
    .usName {
        color: white;
        font-size: 2vw;
        font-family: 'proxima-semi-bold';
    }
    .rC {
        display: flex;
        flex-direction: column;
    }
    .joinWText {
        font-family: 'proxima-semi-bold';
        font-size: 1.7vw;
    }
    .transCont {
        background-color: rgba(255, 255, 255, 0.075);
        padding: 15px;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    .urlPr {
        color: white;
        font-size: 1.4vw;
    }
    .checkM {
        margin-top: 10px;
        color: white;
        font-size: 1.3vw;
        font-family: 'proxima-semi-bold';
        display: flex;
        align-items: center;
    }
    .hrt {
        position: absolute;
        bottom: 0;
        left: 7vw;
        width: 2.5vw;
    }
    .stepText {
        font-size: 1.5vw;
        color: white;
    }
    .numText {
        font-size: 1.5vw;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 0.4px solid white;
        border-radius: 50%;
        margin-right: 10px;
    }
    .insta{
        position: relative;
    }
    .secCont{
        width: 100%;
        padding-left: 200px;
        padding-right: 200px;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .info {
        margin-top: 120px;
        display: flex;
        justify-content: space-evenly;
        width: 90%;
        margin-bottom: 30px;
        color: white;
    }
    .postBtn {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        margin-top: 30px;
        margin-bottom: 200px;
        background-color: black;
        color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 35px;
        font-size: 1.5vw;
    }
    .modalCont {
        width: 35%;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        align-items: center;
        font-family: 'proxima';
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: black;
        cursor: pointer;
    }
    .inp {
        all: unset;
        padding: 10px;
        border: 2px solid rgba(0, 0, 0, 0.443);
        box-sizing: border-box;
        border-radius: 10px;
        width: 100%;
        margin-top: 30px;
    }
    .postBtn2 {
        padding: 10px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 200px;
        font-family: 'proxima-semi-bold';
        font-size: 1.3vw;
        margin-bottom: 30px;
        background-color: black;
        color: white;
        width: 100%;
        margin-top: 30px;
    }
    .options {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.7vw;
        font-family: 'proxima-bold';
    }
    .money {
        margin-top: 10px;
        font-family: 'proxima-semi-bold';
    }
    .disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    .rd {
        margin-right: 10px;
    }
    .overlay {
        object-fit: contain;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 
        background-image: url('../../assets/images/coke_bg_darker.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .agree {
        margin-top: 20px;
    }
    .ruleText {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5vw;
    }
    .ruleCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .subTit {
        margin-top: 10px;
        font-family: 'proxima-bold';
        font-size: 1.6vw;
        margin-bottom: 10px;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    
    .instaLogo{
        width: 18vw;
        margin-top: 10px;    
    }
    .loading {
        width: 100%;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .confirmCont {
        display: flex;
        flex-direction: column;
      }
      .congText {
        font-family: 'proxima-bold';
        font-size: 1.6vw;
        margin-top: 10px;
      }
      .signupContDesk {
        display: flex; 
        align-items: center;
    }
    .learnM {
        margin-left: 60px;
         color: white;
        font-size: 1.5vw;
        cursor: pointer;
    }
    .urlStuff {
        display: flex;
        align-items: center;
    }
    .shareCont {
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
     }
     .signupContMob {
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: none;
    }
    .finger {
        font-size: 2vw; 
        margin-right: 10px; 
    }
    .leftCMob {
        display: none;
    }
    .rightCDesk {
        display: block;
    }
    .sendText {
        font-size: 1.4vw;
    }
    .profPicCont {
        width: 220px;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
    }
}