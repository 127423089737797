.container {
    width: 100%;
    height: 100vh;
    font-family: 'proxima';
    background-image: url('../../assets/images/coke_bg_darker_taller3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topC {
    width: 100%;
    height: 60%;
    position: relative;
}
.pRule {
    color: #999999; 
    font-size: 0.9vw; 
    margin-top: 5px; 
    margin-left: 25px;
}
.layer{
    width: 100%;
    height: 50vh;
    /* background-color: rgba(0, 0, 0, 0.6); */
}
.formC {
    width: 25%;
    height: 60vh;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
}
.abs {
    position: absolute;
    width: 100%;
    height: 90%;
    bottom: -20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.inp {
    all: unset;
    padding: 10px;
    border-bottom: 0.8px solid black;
    box-sizing: border-box;
    margin-top: 10px;
}
.inpF {
    all: unset;
    padding: 10px;
    border-bottom: 0.8px solid black;
    box-sizing: border-box;
    margin-top: 10px;
    width: 50%;
    margin-right: 10px;
}
.inpL {
    all: unset;
    padding: 10px;
    border-bottom: 0.8px solid black;
    box-sizing: border-box;
    margin-top: 10px;
    width: 50%;
}
.innerF {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    justify-content: space-around;
    margin-top: 2px;
}
.nameC {
    display: flex;
    align-items: center;
}
.signUpBtn{
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    font-family: 'proxima-semi-bold';
    margin-bottom: 10px;
    background-color: black;
    color: white;
    margin-top: 20px;
    font-size: 1.3vw;
}
.signUpBtn2{
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    font-family: 'proxima-semi-bold';
    margin-bottom: 10px;
    background: rgb(131,83,215);
    background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
    color: white;
    margin-top: 20px;
    font-size: 1.3vw;
}
.overlay {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .lgo {
    height: 70px;
    margin-bottom: 16px;
}
.welc {
    font-family: 'proxima-bold';
    font-size: 1.1vw;
    color: #5b5b5b;
}
.instaButtonReal {
    display: none;
    border: 0;
    border-Radius: 25PX;
    box-Shadow: rgba(0, 0, 0, 0.5) 0 1px 2px;
    color: #ffffff;
    cursor: pointer;
    font-Size: 17px;
    margin: 5px;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    user-Select: none;
    background:
      linear-gradient(to right, rgb(214, 146, 60) 0%, rgb(160, 11, 143) 50%, rgb(56, 10, 115) 100%);
}
.instaButton {
    display: block;
    border: 0;
    border-Radius: 25PX;
    box-Shadow: rgba(0, 0, 0, 0.5) 0 1px 2px;
    color: #ffffff;
    cursor: pointer;
    font-Size: 17px;
    margin: 5px;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    user-Select: none;
    background:
      linear-gradient(to right, rgb(214, 146, 60) 0%, rgb(160, 11, 143) 50%, rgb(56, 10, 115) 100%);
}
.instaLogo{
    width: 6vw;
}
.formCSignIn {
    width: 25%;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
}
.forgotText {
    font-size: 1vw; 
    cursor: pointer; 
    color: #999999; 
    margin-bottom: 5px;
}
.verifyText {
    font-size: 1.1vw;
}
.needAcc {
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; 
    margin-top: 12px;
}
.box {
    color: black;
    padding: 30px 20px 30px 20px;
    box-sizing: border-box;
    width: 40%;
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: white;
    opacity: 0.8;
}
.greenC {
    width: 2.5vw;
    margin-bottom: 10px;
}
.thankText {
    font-size: 1.3vw;
    line-height: 35px;
}
.stat {
    margin-bottom: 10px; 
    font-weight: bold; 
    font-size: 1.8vw;
}
@media (max-width: 768px) {
    .container {
        width: 100%;
        height: 100vh;
        font-family: 'proxima';
        background-image: url('../../assets/images/coke_bg_mobile.jpg');
        background-size: cover;
        display: flex;
        flex-direction: column;
        background-position: center;
    }
    .formC {
        width: 70%;
        height: 50vh;
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
    }
    .abs {
        position: absolute;
        width: 100%;
        height: 90%;
        bottom: -10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .pRule {
        color: #999999; 
        font-size: 3vw; 
        margin-top: 5px; 
        margin-left: 25px;
    }
    .inp {
        all: unset;
        padding: 10px;
        border-bottom: 0.8px solid black;
        box-sizing: border-box;
        margin-top: 10px;
    }
    .inpF {
        all: unset;
        padding: 10px;
        border-bottom: 0.8px solid black;
        box-sizing: border-box;
        margin-top: 10px;
        width: 50%;
        margin-right: 10px;
    }
    .inpL {
        all: unset;
        padding: 10px;
        border-bottom: 0.8px solid black;
        box-sizing: border-box;
        margin-top: 10px;
        width: 50%;
    }
    .innerF {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
        box-sizing: border-box;
        justify-content: space-around;
        margin-top: 2px;
    }
    .nameC {
        display: flex;
        align-items: center;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        font-size: 4.2vw;
        margin-top: 20px;
    }
    .welc {
        font-family: 'proxima-bold';
        font-size: 4vw;
        color: #5b5b5b;
    }
    .instaLogo{
        width: 20vw;
    }
    .lgo {
        height: 70px;
        margin-bottom: 16px;
    }
    .formCSignIn {
        width: 70%;
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
    }
    .forgotText {
        font-size: 3.5vw; 
        cursor: pointer; 
        color: #999999; 
        margin-bottom: 5px;
    }
    .needAcc {
        font-size: 3vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px; 
        margin-top: 12px;
    }
    .verifyText {
        font-size: 4vw;
    }
    .box {
        color: black;
        padding: 40px 20px 40px 20px;
        box-sizing: border-box;
        width: 90%;
        display: flex;
        flex-direction: column;
        border-radius: 18px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: white;
        opacity: 0.8;
    }
    .greenC {
        width: 10vw;
        margin-bottom: 10px;
    }
    .thankText {
        font-size: 5vw;
        line-height: 35px;
    }
    .stat {
        margin-bottom: 10px; 
        font-weight: bold; 
        font-size: 6vw;
    }
}
@media screen and (min-width: 2000px) {
    .container {
        width: 100%;
        height: 100vh;
        font-family: 'proxima';
        background-image: url('../../assets/images/coke_bg_darker_taller3.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
    }
    .topC {
        width: 100%;
        height: 60%;
        position: relative;
    }
    .pRule {
        color: #999999; 
        font-size: 0.7vw; 
        margin-top: 5px; 
        margin-left: 25px;
    }
    .layer{
        width: 100%;
        height: 50vh;
        /* background-color: rgba(0, 0, 0, 0.6); */
    }
    .formC {
        width: 20%;
        height: 60vh;
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
    }
    .abs {
        position: absolute;
        width: 100%;
        height: 90%;
        bottom: 0px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .inp {
        all: unset;
        padding: 10px;
        border-bottom: 0.8px solid black;
        box-sizing: border-box;
        margin-top: 10px;
    }
    .inpF {
        all: unset;
        padding: 10px;
        border-bottom: 0.8px solid black;
        box-sizing: border-box;
        margin-top: 10px;
        width: 50%;
        margin-right: 10px;
    }
    .inpL {
        all: unset;
        padding: 10px;
        border-bottom: 0.8px solid black;
        box-sizing: border-box;
        margin-top: 10px;
        width: 50%;
    }
    .innerF {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
        box-sizing: border-box;
        justify-content: space-around;
        margin-top: 2px;
    }
    .nameC {
        display: flex;
        align-items: center;
    }
    .signUpBtn{
        padding: 10px;
        border-radius: 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        margin-bottom: 10px;
        background-color: black;
        color: white;
        margin-top: 40px;
        font-size: 1.3vw;
    }
    .signUpBtn2{
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        font-family: 'proxima-semi-bold';
        margin-bottom: 10px;
        background: rgb(131,83,215);
        background: linear-gradient(90deg, rgba(131,83,215,1) 0%, rgba(142,217,231,1) 100%);
        color: white;
        margin-top: 20px;
        font-size: 1.3vw;
    }
    .overlay {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .lgo {
        height: 70px;
        margin-bottom: 16px;
    }
    .welc {
        font-family: 'proxima-bold';
        font-size: 1.1vw;
        color: #5b5b5b;
    }
    .instaButtonReal {
        display: none;
        border: 0;
        border-Radius: 30px;
        box-Shadow: rgba(0, 0, 0, 0.5) 0 1px 2px;
        color: #ffffff;
        cursor: pointer;
        font-Size: 17px;
        margin: 5px;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        user-Select: none;
        background:
          linear-gradient(to right, rgb(214, 146, 60) 0%, rgb(160, 11, 143) 50%, rgb(56, 10, 115) 100%);
    }
    .instaButton {
        display: block;
        border: 0;
        border-Radius: 40px;
        box-Shadow: rgba(0, 0, 0, 0.5) 0 1px 2px;
        color: #ffffff;
        cursor: pointer;
        font-Size: 17px;
        margin: 5px;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        user-Select: none;
        background:
          linear-gradient(to right, rgb(214, 146, 60) 0%, rgb(160, 11, 143) 50%, rgb(56, 10, 115) 100%);
    }
    .instaLogo{
        width: 5vw;
    }
    .formCSignIn {
        width: 20%;
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
    }
    .forgotText {
        font-size: 0.8vw; 
        cursor: pointer; 
        color: #999999; 
        margin-bottom: 5px;
    }
    .verifyText {
        font-size: 1vw;
    }
    .haveText {
        font-size: 1vw;
    }
    .needAcc {
        font-size: 0.9vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px; 
        margin-top: 12px;
    }
    .box {
        color: black;
        padding: 40px 25px 40px 25px;
        box-sizing: border-box;
        width: 35%;
        display: flex;
        flex-direction: column;
        border-radius: 18px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: white;
        opacity: 0.8;
    }
    .greenC {
        width: 2.5vw;
        margin-bottom: 10px;
    }
    .thankText {
        font-size: 1.3vw;
        line-height: 35px;
    }
    .stat {
        margin-bottom: 10px; 
        font-weight: bold; 
        font-size: 1.6vw;
    }
}