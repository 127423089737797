.container {
    background-color: black;
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: space-between;
    padding: 40px;
    box-sizing: border-box;
    font-family: 'proxima';
}
.lgo {
    height: 6vh;
}
.leftC {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text{
    font-size: 18px;
    color: white;
    margin-top: 10px;
}
.cpyR1 {
    font-size: 13px;
    color: white;
    margin-top: 30px;
}
.cpyR2 {
    font-size: 13px;
    color: white;
    margin-top: 30px;
    display: none;
}
.rightC {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: end;
}
.abt {
    font-size: 18px;
    color: white;
}
.social {
    font-size: 18px;
    color: white;
}
.cards {
    display: flex; 
    justify-content: space-between;
    width: 85%; 
    margin-top: 25px;
}
a { 
    color: inherit; 
    all: unset;
} 
.textMob {
    display: none;
}
.mobCards {
    display: none;
}
.desCards {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
@media only screen and (max-width: 767px) {
    .container {
        background-color: black;
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 30px 15px 30px 15px;
        box-sizing: border-box;
        font-family: 'proxima';
    }
    .lgo {
        width: 19vh;
        height: auto;
    }
    .leftC {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .text{
        font-size: 15px;
        color: white;
        width: 100%;
        display: none;
    }
    .textMob {
        margin-top: 10px;
        font-size: 15px;
        color: white;
        width: 100%;
        display: block;
    }
    .cpyR1 {
        font-size: 13px;
        color: white;
        margin-top: 20px;
        margin-bottom: 20px;
        display: none;
    }
    .tCont {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .rightC {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: flex-start;
        width: 100%;
        margin-top: 40px;
    }
    .abt {
        font-size: 18px;
        color: white;
        margin-right: 0px;
    }
    .social {
        font-size: 18px;
        color: white;
    }
    .lHelp {
        display: flex;
        flex-direction: column;
    }
    .cards {
        display: flex; 
        justify-content: space-between;
        align-items: center;
        width: 70%; 
        margin-top: 25px;
    }
    .cpyR2 {
        font-size: 13px;
        color: white;
        margin-top: 30px;
        display: block;
    }
    .mobCards {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .desCards {
        display: none;
    }
}

@media screen and (min-width: 2000px) {
    .container {
        background-color: black;
        width: 100%;
        height: 30vh;
        display: flex;
        justify-content: space-between;
        padding: 40px 100px 40px 100px;
        box-sizing: border-box;
        font-family: 'proxima';
    }
    .lgo {
        height: 6vh;
    }
    .leftC {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .text{
        font-size: 18px;
        color: white;
        margin-top: 10px;
    }
    .cpyR1 {
        font-size: 13px;
        color: white;
        margin-top: 30px;
    }
    .cpyR2 {
        font-size: 13px;
        color: white;
        margin-top: 30px;
        display: none;
    }
    .rightC {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: end;
    }
    .abt {
        font-size: 18px;
        color: white;
    }
    .social {
        font-size: 18px;
        color: white;
    }
    .cards {
        display: flex; 
        justify-content: space-between;
        width: 85%; 
        margin-top: 25px;
    }
    a { 
        color: inherit; 
        all: unset;
    } 
    .textMob {
        display: none;
    }
    .mobCards {
        display: none;
    }
    .desCards {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}