.modalCont {
    width: 60%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    font-family: 'proxima';
    overflow: hidden;
    background-color: white;
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
}
.titles {
    font-size: 1.8vw;
    font-family: 'proxima-black';
    margin-bottom: 20px;
}
.signUpBtn{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'proxima-semi-bold';
    margin-top: 30px;
    background-color: black;
    color: white;
    padding: 10px 35px 10px 35px;
    border-radius: 35px;
    font-size: 1.5vw;
}

@media only screen and (max-width: 767px) {
    .modalCont {
        width: 100%;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        align-items: center;
        font-family: 'proxima';
        overflow: hidden;
        
    }
    .titles {
        font-size: 5vw;
        font-family: 'proxima-black';
        margin-bottom: 20px;
    }
    .signUpBtn{
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        margin-top: 30px;
        background-color: black;
        color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 35px;
        font-size: 4vw;
    }
}