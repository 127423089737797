.polCont {
    width: 25vw;
    height: 55vh;
    background-color: #fbf7f3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.whiteCont {
    display: flex;
    padding: 20px;
    padding-bottom: 5px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fbf7f3;
}
.bCont {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    background-image: url('../../assets/images/placeholderUserProfile.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.userWrapper {
    padding-left: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.linkWrap {
    display: flex; 
    flex-direction: row; 
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-left: 20px;
    margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
    .polCont {
        width: 80vw;
        height: 60vh;
        background-color: #fbf7f3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .whiteCont {
        display: flex;
        padding: 20px;
        padding-bottom: 5px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fbf7f3;
    }
    .bCont {
        background-color: black;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        background-image: url('../../assets/images/placeholderUserProfile.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .userWrapper {
        padding-left: 20px; 
        padding-right: 20px; 
        padding-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}