.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
  }
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
  
  .checkmark:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
  }
  
  .checked {
    display: none;
  }
  .boxIN {
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }

  @media only screen and (max-width: 767px) {
    .checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        user-select: none;
      }
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
  
  .checkmark:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
  }
  .checked {
    display: none;
  }
  .boxIN {
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
  }
  @media screen and (min-width: 2000px) {
    .checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        user-select: none;
      }
      
      .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      .checkmark {
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
      }
      
      .checkmark:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
      }
      
      .checked {
        display: none;
      }
      .boxIN {
        position: absolute;
        top: 50%;
        left: -6px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 3px;
      }
  }