.inp {
    all: unset;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.443);
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    background-color: white;
}
.inpEmail {
    all: unset;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.443);
    box-sizing: border-box;
    border-radius: 10px;
    width: 98%;
}
.modalCont {
    width: 30%;
    height: 70%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    font-family: 'proxima';
    overflow: hidden;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
    z-index: 12;
}
.titles {
    font-size: 1.6vw;
    font-family: 'proxima-semi-bold';
}
.titles2 {
    font-size: 1.6vw;
    font-family: 'proxima-semi-bold';
    margin-top: 30px;
    margin-bottom: 10px;
}
.postBtn {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'proxima-semi-bold';
    background-color: black;
    color: white;
    padding: 10px 35px 10px 35px;
    box-sizing: border-box;
    border-radius: 35px;
    font-size: 1.5vw;
    width: 100%;
}
.polCont {
    width: 25vw;
    height: 60vh;
    background-color: #fbf7f3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.whiteCont {
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fbf7f3;
}
.bCont {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}
.txtWrapper {
    padding-left: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: black;
    cursor: pointer;
}
.imPlace {
    width: 2.9vw;
}
.imgToShow {
    width: 100%;
    position: absolute;
}
.tapBtn {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 18px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'proxima';
    background-color: white;
    color: black;
    font-size: 13px;
}
.postType {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}
.initialWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.congCont {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.ruleText {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3vw;
}
.ruleCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}
.imageToShowCont {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.bulletText {
    margin-top: 20px;
    font-size: 1.4vw; 
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .modalCont {
        width: 100%;
        height: 50vh;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        align-items: center;
        font-family: 'proxima';
        overflow: hidden;
        
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: black;
        cursor: pointer;
    }
    .titles {
        font-size: 5vw;
        font-family: 'proxima-semi-bold';
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .titles2 {
        font-size: 5vw;
        font-family: 'proxima-semi-bold';
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .signUpBtn{
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        margin-top: 30px;
        background-color: black;
        color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 35px;
        font-size: 5vw;
    }
    .pText {
        color: white; 
        font-size: 4vw;
    }

    .polCont {
        width: 100%;
        height: 50vh;
        background-color: #fbf7f3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .whiteCont {
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fbf7f3;
    }
    .bCont {
        background-color: black;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .txtWrapper {
        padding-left: 20px; 
        padding-right: 20px; 
        padding-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .imgToShow {
        width: 100%;
        position: absolute;
    }
    .postBtn {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        padding: 10px 35px 10px 35px;
        box-sizing: border-box;
        border-radius: 35px;
        font-size: 4vw;
        width: 100%;
    }
    .imPlace {
        width: 10vw;
    }
    .imageToShowCont {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .bulletText {
        margin-top: 20px;
        font-size: 4.5vw; 
        margin-bottom: 15px;
    }
    .ruleText {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4vw;
    }
}
@media screen and (min-width: 2000px) {
    .inp {
        all: unset;
        padding: 10px;
        border: 2px solid rgba(0, 0, 0, 0.443);
        box-sizing: border-box;
        width: 100%;
        border-radius: 5px;
        background-color: white;
    }
    .inpEmail {
        all: unset;
        padding: 10px;
        border: 2px solid rgba(0, 0, 0, 0.443);
        box-sizing: border-box;
        border-radius: 10px;
        width: 98%;
    }
    .modalCont {
        width: 30%;
        height: 64%;
        position: relative;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 30px;
        padding-top: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        align-items: center;
        justify-content: space-between;
        font-family: 'proxima';
        overflow: hidden;
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    .close {
        position: absolute;
        top: 12px;
        right: 15px;
        color: black;
        cursor: pointer;
        z-index: 12;
    }
    .titles {
        font-size: 1.6vw;
        font-family: 'proxima-semi-bold';
    }
    .titles2 {
        font-size: 1.6vw;
        font-family: 'proxima-semi-bold';
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .postBtn {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima-semi-bold';
        background-color: black;
        color: white;
        padding: 10px 35px 10px 35px;
        box-sizing: border-box;
        border-radius: 35px;
        font-size: 1.5vw;
        width: 100%;
    }
    .polCont {
        width: 25vw;
        height: 60vh;
        background-color: #fbf7f3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    .whiteCont {
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fbf7f3;
    }
    .bCont {
        background-color: black;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .txtWrapper {
        padding-left: 20px; 
        padding-right: 20px; 
        padding-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: black;
        cursor: pointer;
    }
    .imPlace {
        width: 2.9vw;
    }
    .imgToShow {
        width: 100%;
        position: absolute;
    }
    .tapBtn {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 18px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'proxima';
        background-color: white;
        color: black;
        font-size: 13px;
    }
    .postType {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .initialWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .congCont {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .ruleText {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3vw;
    }
    .ruleCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }
    .imageToShowCont {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .bulletText {
        margin-top: 20px;
        font-size: 1.4vw; 
        margin-bottom: 15px;
    }
}